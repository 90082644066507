import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import _ from "lodash"; // For deep cloning
import axios from "../../utilities/axios";
import { addObjectiveToCategory } from "../../utilities/objectifProcess";
import { setCategories, setLevelOneNotMentioned } from "./categories";

const initialState = {
  objectives: [],
  objectivesWithAlertes: [],
  doesProjectHasObjectives: false,
  objectif: "",
  status: "idle",
  createStatus: "idle",
  objectifStatus: "idle",
  updateStatus: "idle",
  deleteStatus: "idle",
  showObjectifTocreate: false,
  showListObjectif: false,
  showObjectifToEdit: false,
  showObjectifToDelete: false,
  duplicateObjective: "",
  ElementsOfObjectiveStatus: "idle",
  ElementsOfObjective: [],
  editObjectiveStatus: "idle",
  objectifWithChanges: "",
  openedObjectif: { openedObjectif: "", isLocked: true },
  sumOfAutomaticValues: 0,
};

export const getObjectives = createAsyncThunk(
  "objectif/getObjectives",
  async (queries) => {
    let data;
    try {
      const response = await axios.get(
        `api/objectives/project/${queries.projectId}`
      );
      data = await response.data;
      if (response.status === 200) {
        return {
          data: data,
          message: data.message,
        };
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const getObjectivesWithAlertes = createAsyncThunk(
  "objectif/getObjectivesWithAlertes",
  async (queries) => {
    let data;
    try {
      const response = await axios.get(
        `api/objectives/project/${queries.projectId}/alerts`
      );
      data = await response.data;
      if (response.status === 200) {
        return {
          data: data,
          message: data.message,
        };
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const createObjective = createAsyncThunk(
  "objectif/createObjective",
  async (queries, thunkAPI) => {
    let data;
    try {
      const response = await axios.post(
        `/api/objectives/project/${queries.projectId}`,
        queries?.data
      );
      data = await response.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      console.error({ err });
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const postDuplicateObjective = createAsyncThunk(
  "objectif/duplicateObjective",
  async (queries, thunkAPI) => {
    let data;
    try {
      const response = await axios.post(
        `/api/objectives/${queries.objectId}/duplicate`
      );
      data = await response.data;
      if (response.status === 200) {
        thunkAPI.dispatch(
          getObjectivesWithAlertes({ projectId: data.data.projectId })
        );
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      console.error({ err });
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const getOneObjective = createAsyncThunk(
  "objectives/getOneObjective",
  async (objectiveId) => {
    let data;
    try {
      const response = await axios.get(`api/objectives/${objectiveId}`);
      data = await response.data;
      if (response.status === 200) {
        return {
          data: data,
          message: data.message,
        };
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : err);
    }
  }
);
export const updateObjective = createAsyncThunk(
  "objectives/updateObjective",
  async (body, thunkAPI) => {
    let data;
    const { objectiveId } = body;
    try {
      const response = await axios.put(`api/objectives/${objectiveId}`, {
        ...body.data,
      });
      data = await response.data;
      if (response.status === 200) {
        thunkAPI.dispatch(getOneObjective(objectiveId));

        return data.message;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const deleteObjective = createAsyncThunk(
  "objectives/deleteObjective",
  async (objectId, thunkAPI) => {
    let data;
    try {
      const response = await axios.delete(`api/objectives/${objectId}`);
      data = await response.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      console.error({ err });
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const getElementsOfObjective = createAsyncThunk(
  "objectives/getElementsOfObjective",
  async (objectiveId, thunkAPI) => {
    let data;
    try {
      const response = await axios.get(
        `api/objectives/element/${objectiveId}/all`
      );

      if (response.status === 200) {
        data = await response.data?.data;

        const categories = _.cloneDeep(
          thunkAPI.getState().categories?.categoriesOriginal
        );

        const {
          updatedCategoriesWithObjectives,
          sumOfAutomaticValues,
          doesAnyOflevelOnenotMentioned,
        } = addObjectiveToCategory(categories, data);
        console.log({ updatedCategoriesWithObjectives });
        thunkAPI.dispatch(setCategories(updatedCategoriesWithObjectives));
        thunkAPI.dispatch(setAutomaticValuesList(sumOfAutomaticValues));
        thunkAPI.dispatch(
          setLevelOneNotMentioned(doesAnyOflevelOnenotMentioned)
        );
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : err);
    }
  }
);

export const createElementsOfObjectif = createAsyncThunk(
  "objectif/createElementsOfObjectif",
  async (values, thunkAPI) => {
    let data;
    try {
      const response = await axios.post(
        `/api/objectives/element/${values.objectiveId}`,
        values?.data
      );

      data = await response.data;
      if (response.status === 200) {
        thunkAPI.dispatch(getOneObjective(values.objectiveId));
        thunkAPI.dispatch(getElementsOfObjective(values.objectiveId));
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      console.error({ err });
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const editElementsOfObjectif = createAsyncThunk(
  "objectif/editElementsOfObjectif",
  async (values, thunkAPI) => {
    let data;
    try {
      const response = await axios.put(
        `/api/objectives/element/${values.elementId}`,

        values?.data
      );
      data = await response.data;
      if (response.status === 200) {
        thunkAPI.dispatch(getElementsOfObjective(values.objectiveId));
        thunkAPI.dispatch(getOneObjective(values.objectiveId));
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      console.error({ err });
      return thunkAPI.rejectWithValue(err);
    }
  }
);
const slice = createSlice({
  name: "objectif",
  initialState,
  reducers: {
    openCreateObjectiveDialog: (state, action) => {
      state.showObjectifTocreate = action.payload;
    },
    setAutomaticValuesList: (state, action) => {
      state.sumOfAutomaticValues = action.payload;
    },
    setObejectifToChange: (state, action) => {
      state.objectifWithChanges = action.payload;
    },
    openListObjectiveDialog: (state, action) => {
      state.showListObjectif = action.payload;
    },
    openEditObjectiveDialog: (state, action) => {
      state.showObjectifToEdit = action.payload;
    },
    openDeleteObjectiveDialog: (state, action) => {
      state.showObjectifToDelete = action.payload;
    },
    setDuplicateObjective: (state, action) => {
      state.duplicateObjective = action.payload;
    },
    lockunlockObjectif: (state, action) => {
      state.openedObjectif = action.payload;
    },
  },
  extraReducers: {
    [getObjectives.pending]: (state) => {
      state.status = "loading";
    },
    [getObjectives.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.objectives = action.payload.data.data.docs;
      state.doesProjectHasObjectives =
        action.payload.data &&
        action.payload.data.data &&
        action.payload.data.data.docs?.length > 0;
    },
    [getObjectives.failed]: (state, action) => {
      state.objectives = "failed";
      state.error = action.error.message;
    },

    [getObjectivesWithAlertes.pending]: (state) => {
      state.status = "loading";
    },
    [getObjectivesWithAlertes.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.objectivesWithAlertes = action.payload.data.data;
      state.doesProjectHasObjectives =
        action.payload.data &&
        action.payload.data.data &&
        action.payload.data.data?.length > 0;
    },
    [getObjectivesWithAlertes.failed]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },

    [getOneObjective.pending]: (state) => {
      state.objectifStatus = "loading";
    },
    [getOneObjective.fulfilled]: (state, action) => {
      state.objectifStatus = "succeeded";
      state.objectif = action.payload.data.data;
    },
    [getOneObjective.failed]: (state, action) => {
      state.objectifStatus = "failed";
      state.error = action.error.message;
    },
    [createObjective.pending]: (state, action) => {
      state.createStatus = "loading";
    },
    [createObjective.fulfilled]: (state, action) => {
      state.createStatus = "succeeded";
    },
    [createObjective.rejected]: (state, action) => {
      state.createStatus = "failed";
      state.createError = action.payload;
    },
    [createElementsOfObjectif.pending]: (state, action) => {
      state.createStatus = "loading";
    },
    [createElementsOfObjectif.fulfilled]: (state, action) => {
      state.createStatus = "succeeded";
    },
    [createElementsOfObjectif.rejected]: (state, action) => {
      state.createStatus = "failed";
      state.createError = action.payload;
    },

    [updateObjective.pending]: (state) => {
      state.updateStatus = "loading";
    },
    [updateObjective.fulfilled]: (state) => {
      state.updateStatus = "succeeded";
    },
    [updateObjective.rejected]: (state) => {
      state.updateStatus = "failed";
    },
    [deleteObjective.pending]: (state) => {
      state.deleteStatus = "loading";
    },
    [deleteObjective.fulfilled]: (state) => {
      state.deleteStatus = "succeeded";
    },
    [deleteObjective.rejected]: (state) => {
      state.deleteStatus = "failed";
    },
    [getElementsOfObjective.pending]: (state) => {
      state.ElementsOfObjectiveStatus = "loading";
    },
    [getElementsOfObjective.fulfilled]: (state, action) => {
      state.ElementsOfObjectiveStatus = "succeeded";
      state.ElementsOfObjective = action.payload;
    },
    [getElementsOfObjective.rejected]: (state) => {
      state.ElementsOfObjectiveStatus = "failed";
    },
    [editElementsOfObjectif.pending]: (state) => {
      state.editObjectiveStatus = "loading";
    },
    [editElementsOfObjectif.fulfilled]: (state, action) => {
      state.editObjectiveStatus = "succeeded";
    },
    [editElementsOfObjectif.rejected]: (state) => {
      state.editObjectiveStatus = "failed";
    },
  },
});
export const {
  openEditObjectiveDialog,
  openCreateObjectiveDialog,
  openListObjectiveDialog,
  openDeleteObjectiveDialog,
  setAutomaticValuesList,
  setDuplicateObjective,
  lockunlockObjectif,
  setObejectifToChange,
} = slice.actions;
export const reducer = slice.reducer;

export default slice;
