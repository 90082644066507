import { message, Modal, Select } from "antd";
import { Field, Form, Formik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";
import { colors } from "../../../data/constants";
import { createAccount, updateAccount } from "../../../data/slices/accounts";
import { closeModal } from "../../../data/slices/modals";
import { useDispatch } from "../../../data/store";
import useIsMountedRef from "../../../hooks/useIsMountedRef";
import { displayValidationErrors } from "../../../utilities/noticeDisplayer";
import Button from "../../Button";
import CustomInput from "../../Input";
import { useSelector } from "../../../data/store";
import getChangedValues from "../../../utilities/getChangedValues";
import "./index.scss";

function AddCompteModal({
  id,
  open,
  headerContent,
  handleClose,
  data,
  content,
  edit,
  ...rest
}) {
  const dispatch = useDispatch();
  const isMounted = useIsMountedRef();

  const [submitButtonState, setSubmitButtonState] = useState(null);

  const initialValues = {
    name: data?.isEdit ? data?.values?.name : "",
    code: data?.isEdit ? data?.values?.code : "",
    color: data?.isEdit
      ? data?.values?.type === "PRINCIPAL"
        ? "transparent"
        : data?.values?.color
      : "",
  };

  const handleSubmitButtonState = (isSubmit) => {
    setSubmitButtonState(isSubmit);
  };

  const { accounts } = useSelector((state) => state.accounts);

  const usedColors = colors
    ?.filter((color) =>
      accounts.some((account) => account?.color === color.color)
    )
    ?.map((el) => el.color);

  const AccountSchema = Yup.object().shape({
    name: Yup.string()
      .max(20, "Un nom de compte ne peut pas dépasser 20 caractères !")
      .required("Libellé à saisir"),
    code: Yup.string()
      .max(5, "Le code du compte ne peut pas dépasser 5 caractères !")
      .required("Code à saisir"),
    color: Yup.mixed()
      .oneOf(
        ["#c0c0c0", "#d7ac7b", "#e7adff", "transparent"],
        "Couleur à sélectionner"
      )
      .required("Couleur à sélectionner"),
  });

  const onSubmit = async (values, { setSubmitting, resetForm, errors }) => {
    try {
      if (data?.isEdit) {
        const fieldUpdated = getChangedValues(values, initialValues);

        const result = await dispatch(
          updateAccount({
            projectId: data?.projectId,
            values: fieldUpdated,
            accountId: data?.values?.id,
          })
        );
        if (updateAccount.rejected.match(result)) {
          if (isMounted.current) {
            setSubmitting(false);
          }
          displayValidationErrors(
            result?.errors ? result.errors : result.payload
          );
        }
        if (updateAccount.fulfilled.match(result)) {
          message.success("Compte à été ajoutée avec succès");
          dispatch(closeModal("create-compte-modal"));
          resetForm();
        }
      }

      if (!data?.isEdit) {
        const result = await dispatch(
          createAccount({ projectId: data?.projectId, values })
        );
        if (createAccount.rejected.match(result)) {
          if (isMounted.current) {
            setSubmitting(false);
          }
          displayValidationErrors(
            result?.errors ? result.errors : result.payload
          );
        }
        if (createAccount.fulfilled.match(result)) {
          message.success("Compte à été ajoutée avec succès");
          dispatch(closeModal("create-compte-modal"));
          resetForm();
        }
      }
    } catch (error) {}
  };

  return (
    <Modal
      onCancel={() => handleClose(id)}
      zIndex={1001}
      title={
        data?.isEdit
          ? " Modifier un compte de trésorerie:"
          : "Ajouter un compte de trésorerie:"
      }
      className="add-writing-modal"
      maskClosable={false}
      focusTriggerAfterClose={false}
      centred
      open={open}
      footer={false}
      closable={false}
    >
      <p className="description">* champs obligatoires.</p>
      <Formik
        initialValues={initialValues}
        validationSchema={AccountSchema}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({
          values,
          isSubmitting,
          handleChange,
          handleBlur,
          setFieldValue,
          errors,
          resetForm,
          touched,
        }) => {
          return (
            <Form
              className="form-container form-compte"
              autoComplete="off"
              preserve={false}
            >
              <Field
                component={CustomInput}
                value={values.name}
                label="Libellé du compte de trésorerie*:"
                name="name"
                disabled={data?.isEdit && data.values.type === "PRINCIPAL"}
                type="text"
                className={
                  values.code
                    ? "form-element-input small-input form-element-input input-wrapper-writing"
                    : "form-element-input calendar not-filled small-input input-wrapper-writing"
                }
                placeholder="Saisir un libellé"
                onBlur={handleBlur}
                onChange={handleChange}
              />

              <Field
                component={CustomInput}
                value={values.code}
                label="Code du compte (5 caractères max)*:"
                name="code"
                type="text"
                className={
                  values.code && !(errors.code && touched.code)
                    ? "form-element-input small-input input-wrapper-writing"
                    : "form-element-input calendar not-filled small-input input-wrapper-writing"
                }
                placeholder="Saisir un code"
                onBlur={handleBlur}
                onChange={handleChange}
              />

              <div className="wrapper-select">
                <label htmlFor={"color"} className="input-text">
                  Couleur associée au compte*:
                </label>

                <Select
                  placeholder={"Choisir une couleur"}
                  name={"color"}
                  value={values.color || null}
                  disabled={data?.isEdit && data?.values?.type === "PRINCIPAL"}
                  size="large"
                  bordered={false}
                  onChange={(val) => setFieldValue("color", val)}
                  className={
                    values.color
                      ? "form-element-input small-input "
                      : "form-element-input calendar not-filled small-input"
                  }
                >
                  <Select.Option
                    value={"transparent"}
                    disabled={!edit}
                    className="option"
                  >
                    <div className="img-container element-status">
                      <div> transparent</div>
                    </div>
                  </Select.Option>
                  {colors.map((option) => (
                    <Select.Option
                      key={option.key}
                      value={option.color}
                      disabled={usedColors.includes(option.color)}
                      className="option"
                    >
                      <div className="img-container element-status">
                        <div
                          className="color-box"
                          style={{ backgroundColor: option.color }}
                        >
                          {option.label}
                        </div>
                      </div>
                    </Select.Option>
                  ))}
                </Select>
                {errors && touched ? (
                  <div className="error-displayer-form-modal">
                    {errors?.color}
                  </div>
                ) : null}
              </div>

              <div className="submit-button-wrapper">
                <Button
                  text={"Valider"}
                  type="submit"
                  disabled={isSubmitting}
                  color="primary"
                  className="submit-button"
                  allWidth
                  onClick={() => handleSubmitButtonState("validate")}
                />
                <Button
                  text="Annuler"
                  color="primary"
                  className="reset-button"
                  allWidth
                  onClick={(e) => {
                    handleClose(id);
                    resetForm();
                    e.preventDefault();
                  }}
                />
              </div>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
}

export default AddCompteModal;
