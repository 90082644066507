import React from "react";

const Overlay = ({ children, show }) => {
  const overlayStyles = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    padding: "3.2rem 15.1rem",
    display: show ? "flex" : "none",
    zIndex: 1001,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  };

  return <div style={overlayStyles}>{children}</div>;
};

export default Overlay;
