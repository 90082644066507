function addObjectiveToCategory(categories, objectifs) {
  try {
    let doesAnyOflevelOnenotMentioned = false;
    let sumOfAutomaticValues = 0;

    const addObjective = (category, objectif) => {
      if (category.isRacine) return false;

      if (Number(category.id) === Number(objectif.categoryId)) {
        category.objectif = objectif;
        return true;
      }

      if (category.children) {
        for (const child of category.children) {
          if (addObjective(child, objectif)) {
            return true;
          }
        }
      }
      return false;
    };

    objectifs.forEach((objectif) => {
      for (const category of categories) {
        if (addObjective(category, objectif)) {
          break;
        }
      }
    });

    const updateCategories = (categories) => {
      return categories.map((category) => {
        if (category.isRacine) return category;

        const updatedCategory = { ...category };

        if (updatedCategory.children) {
          const updatedChildren = updateCategories(updatedCategory.children);

          const doesAnyOfTheChildrenHasObjective = updatedChildren.some(
            (child) => child.objectif && child.objectif?.amount != null
          );

          const hasObjectif =
            !!category.objectif && updatedCategory?.objectif?.amount;

          const totalChildrenValue = updatedChildren.reduce((sum, child) => {
            return (
              sum +
              (child.objectif && child.objectif.amount
                ? parseFloat(child.objectif.amount)
                : 0)
            );
          }, 0);

          // Only check for level 1 categories
          if (updatedCategory.levelId === 1) {
            const AnyCategoryOf1stHasChild = categories.some((category) => {
              return category.objectif && category.levelId === 1;
            });

            if (AnyCategoryOf1stHasChild && !hasObjectif) {
              updatedCategory.notMentioned = true;
              doesAnyOflevelOnenotMentioned = true;
            }
          }

          if (updatedCategory.levelId !== 1) {
            const anychildHasObjectif = categories.some((category) => {
              return category.objectif && category.objectif.amount != null;
            });

            if (anychildHasObjectif && !hasObjectif) {
              updatedCategory.notMentioned = true;
            }
          }

          updatedCategory.children = updatedChildren;

          //////// auto value
          updatedCategory.hasAutomaticValue =
            doesAnyOfTheChildrenHasObjective && !hasObjectif;

          updatedCategory.automaticValue = totalChildrenValue;

          if (updatedCategory.hasAutomaticValue) {
            updatedCategory.notMentioned = false;
          }

          const hasValueMismatch =
            hasObjectif &&
            updatedCategory.children.length > 0 &&
            doesAnyOfTheChildrenHasObjective &&
            parseFloat(category.objectif.amount) !== totalChildrenValue;

          if (hasValueMismatch) {
            const difference = category.objectif.amount
              ? parseFloat(category.objectif.amount) - totalChildrenValue
              : -totalChildrenValue;

            if (difference !== 0) {
              const existingRacineCategory = updatedChildren.find(
                (child) => child.isRacine === true
              );

              if (existingRacineCategory) {
                existingRacineCategory.objectif = {
                  amount: difference.toFixed(2),
                };
              } else {
                const racineCategory = {
                  id: `racine-${category.id}`,
                  name: `Racine for ${category.name}`,
                  isRacine: true,
                  objectif: {
                    amount: difference.toFixed(2),
                  },
                  levelId: updatedCategory.levelId + 1,
                  children: [],
                };

                updatedChildren.unshift(racineCategory);
              }
            }
          }

          updatedCategory.hasAlert =
            updatedChildren.some((child) => child.notMentioned) ||
            hasValueMismatch;

          updatedCategory.children = updatedChildren;
        }

        return updatedCategory;
      });
    };

    const updatedCategoriesWithObjectives = updateCategories(categories);

    const levelOneCategories = updatedCategoriesWithObjectives.filter(
      (category) => category.levelId === 1
    );

    sumOfAutomaticValues = levelOneCategories
      .filter((el) => el?.hasAutomaticValue)
      .reduce((acc, elem) => acc + (elem?.automaticValue || 0), 0);

    const hasAutomaticValueInLevelOne = levelOneCategories.some(
      (category) => category.hasAutomaticValue
    );

    const allOthersMentioned = levelOneCategories.every(
      (category) => category.hasAutomaticValue || !category.notMentioned
    );

    if (hasAutomaticValueInLevelOne && allOthersMentioned) {
      doesAnyOflevelOnenotMentioned = false;
    }

    return {
      updatedCategoriesWithObjectives,
      sumOfAutomaticValues,
      doesAnyOflevelOnenotMentioned,
    };
  } catch (error) {
    console.log({ error });
  }
}

export { addObjectiveToCategory };
