import { PlusOutlined, DownOutlined } from "@ant-design/icons";
import { message, Dropdown, Menu, Tooltip } from "antd";
import { Field, useFormikContext } from "formik";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import Delete from "../../assets/img/delete.png";
import edit from "../../assets/img/edit.png";
import { closeModal, openModal } from "../../data/slices/modals";
import CustomInput from "../Input";
import { useSelector } from "../../data/store";
import "./index.scss";
import { checkBeforeDelete, deleteAccount } from "../../data/slices/accounts";
import { colors } from "../../data/constants";

const CustomDropdown = ({ projectId, values, onBlur }) => {
  const dispatch = useDispatch();
  const { setFieldValue } = useFormikContext(); // Get setFieldValue from Formik context
  const { accounts } = useSelector((state) => state.accounts);

  const handleDelete = (name, id, event) => {
    event.stopPropagation();
    dispatch(checkBeforeDelete({ projectId, accountId: id })).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        if (res.payload?.data?.data) {
          message.error(
            "Impossible de supprimer ce compte car des écritures lui sont affectées !"
          );
        } else {
          dispatch(
            openModal("delete-compte-modal", {
              accountId: id,
              name,
              abandon: () => dispatch(closeModal("delete-compte-modal")),
              onClose: () => {
                dispatch(deleteAccount({ projectId, accountId: id })).then(
                  (response) => {
                    if (response.meta.requestStatus === "fulfilled") {
                      message.success("Compte supprimé avec succès");
                    }
                  }
                );
                dispatch(closeModal("delete-compte-modal"));
              },
            })
          );
        }
      }
    });
  };

  useEffect(() => {
    const account = accounts.find(
      (el) => Number(el.id) === Number(values?.accountTresoId)
    );

    if (account && account?.name !== values?.accountTresoLabel) {
      setFieldValue("accountTresoLabel", account?.name);
    }
  }, [
    accounts,
    setFieldValue,
    values?.accountTresoId,
    values?.accountTresoLabel,
  ]);

  const openCreateAccount = (data) =>
    dispatch(openModal("create-compte-modal", data));

  const handleMenuSelect = (option) => {
    setFieldValue("accountTresoId", option.id);
    setFieldValue("accountTresoLabel", option.name);
    setFieldValue("accountCode", option.code);
    setFieldValue("accountColor", option.color);
  };

  const handleCreateAccount = (projectId) => {
    if (accounts?.length - 1 === colors.length) return;
    openCreateAccount({
      projectId,
    });
  };

  const menu = (
    <Menu style={{ paddingTop: "10px" }}>
      {accounts?.map((option) => (
        <Menu.Item
          key={option?.id}
          className={"menu-option"}
          onClick={() => handleMenuSelect(option)}
        >
          <span
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <span>{option?.name}</span>
            <span
              className="compte-displayer-filter"
              style={{ backgroundColor: option?.color }}
            >
              {option?.code}
            </span>
          </span>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              gap: "18px",
            }}
          >
            <Tooltip title="Modifier">
              <div
                onClick={() =>
                  openCreateAccount({
                    values: option,
                    isEdit: true,
                    projectId,
                  })
                }
                className="icon-menu-option"
              >
                <img src={edit} alt="edit" />
              </div>
            </Tooltip>
            <Tooltip
              title={
                option?.type === "PRINCIPAL"
                  ? "Il est impossible de supprimer le compte principal !"
                  : "Supprimer"
              }
            >
              <div
                onClick={(e) => {
                  if (option?.type === "PRINCIPAL") return;
                  handleDelete(option.name, option.id, e);
                }}
                className={`icon-menu-option ${
                  option?.type === "PRINCIPAL" ? "disabled" : ""
                }`}
              >
                <img src={Delete} alt="delete" />
              </div>
            </Tooltip>
          </div>
        </Menu.Item>
      ))}

      <Menu.Item
        disabled={accounts?.length - 1 === colors.length}
        onClick={() => handleCreateAccount(projectId)}
        style={{
          marginTop: "10px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "20px",
        }}
      >
        <Tooltip
          title={
            accounts?.length - 1 === colors.length
              ? "Vous avez déjà créé le nombre max de comptes de trésorerie !"
              : ""
          }
        >
          <span className="add-account-select">
            <PlusOutlined />
            <span>Ajouter un compte</span>
            <span className="add-alert">(4 comptes max)</span>
          </span>
        </Tooltip>
      </Menu.Item>
    </Menu>
  );
  return (
    <>
      <Dropdown overlay={menu} trigger={["click"]}>
        <div style={{ position: "relative", width: "48.3%" }}>
          <Field
            component={CustomInput}
            readOnly
            label="Compte de trésorerie:"
            name="accountTresoLabel"
            type="text"
            style={{ width: "100%", cursor: "pointer" }}
            className={"form-element-input small input-wrapper-writing"}
            placeholder="Saisir un code"
            value={`${values?.accountTresoLabel || ""}`}
            onBlur={onBlur}
          />

          <DownOutlined
            style={{
              position: "absolute",
              right: "12px",
              top: "75%",
              transform: "translateY(-50%)",
              pointerEvents: "none",
              width: "12px",
              color: "rgba(0, 0, 0, 0.25)",
            }}
          />
        </div>
      </Dropdown>
    </>
  );
};

export default CustomDropdown;
